@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.slide-vertical-enter {
  opacity: 0;
}

.slide-vertical-enter-active {
  opacity: 1;
  transition: all 300ms;
}

.slide-vertical-exit {
  opacity: 1;
}

.slide-vertical-exit-active {
  opacity: 0;
  transition: all 300ms;
}

.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
  background-color: #dda955 !important;
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

body:has(.nav-open) {
	overflow: hidden;
}